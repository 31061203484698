<template>
  <div class="reason">
    <h2 class="reason__title">Bạn có chắc bạn muốn rời khỏi ứng dụng không?</h2>
    <h3 class="reason__subtitle">Hãy giúp chúng tôi bằng cách để lại phản hồi.</h3>
    <div class="reason__area">
      <menu-reason @set-dialog="setDialog"></menu-reason>
    </div>
    <confirm-dialog v-if=openConfirmDialog @set-dialog="setDialog" :reason=reason></confirm-dialog>
  </div>
</template>

<script>
import MenuReason from '@/components/menu/cancel/reason'
import ConfirmDialog from '@/components/menu/cancel/confirmDialog'

export default {
  components: { MenuReason, ConfirmDialog },
  data () {
    return {
      // 確認用のダイアログを表示中かどうか
      openConfirmDialog: false,
      // 退会理由
      reason: ''
    }
  },
  mounted () {
  },
  methods: {
    /**
     * 確認用ダイアログのフラグの設定
     * @param {Object} payload { isOpen: 表示するかどうか, reason: 退会理由 }
     */
    setDialog (payload) {
      this.openConfirmDialog = payload.isOpen
      this.reason = payload.reason
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.reason {
  margin-bottom: $footer_height;
  padding: 0 $main_padding;
  text-align: center;
  &__title {
    margin: 0;
    margin-top: 20px;
    font-size: 25px;
    font-weight: bold;
    color: #393939;
  }
  &__subtitle {
    width: 70%;
    margin: 0;
    margin-left: 15%;
    font-size: 16px;
    font-weight: normal;
    color: #707070;
  }
  &__area {
    width: 100vw;
    margin-top: 20px;
    margin-left: -$main_padding;
  }
}
</style>
