<template>
  <div class="dialog">
    <div class="dialog__area">
      <h2 class="dialog__area__title">Cám ơn phản hồi của bạn rất nhiều. Bạn có chắc bạn muốn xoá tài khoản không?</h2>
      <h3 class="dialog__area__subtitle">Tài khoản của bạn và tất cả mọi dữ liệu liên quan, bao gồm phản hồi về các bài kiểm tra nói sẽ không còn nữa.</h3>
      <p class="dialog__area__text--top" @click="deleteUser()">Xoá tài khoản</p>
      <p class="dialog__area__text--bottom" @click="$emit('set-dialog', { isOpen: false, reason: '' })">Hủy bỏ</p>
      <processing v-if="isProcessing"></processing>
    </div>
  </div>
</template>

<script>
import Processing from '@/components/common/processing'

export default {
  components: { Processing },
  props: {
    // 退会理由
    reason: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      // 処理中かどうか
      isProcessing: false
    }
  },
  computed: {
    /**
     * @return {String} UID
     */
    uid () {
      return this.$store.getters['user/uid']
    },
    /**
     * @return {Object} ユーザー情報
     */
    user () {
      return this.$store.getters['user/user']
    },
    /**
     * @return {Object} PIN情報
     */
    pin () {
      return this.getPINInfo()()
    }
  },
  methods: {
    /**
     * 退会処理を行う
     */
    deleteUser () {
      this.isProcessing = true

      // ローカルストレージからログイン情報を取得
      const loginProvider = localStorage.getItem('loginProvider')

      // 退会処理情報をセッションに保存
      sessionStorage.setItem('cancelUID', this.uid)
      sessionStorage.setItem('cancelReason', this.reason)

      // Auth認証
      // 続きの処理はonAuthで実施
      this.$store.dispatch('auth/signin', loginProvider)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(74, 74, 74, 0.7);
  z-index: 20;
  &__area {
    height: 314.5px;
    width: 87.5%;
    margin: 0 auto;
    padding-top: 39.5px;
    margin-top: calc((100vh - 314.5px) / 2);
    background-color: white;
    &__title {
      width: 83.5vw;
      margin: 0 auto;
      font-size: 18px;
      font-weight: bold;
      color: #393939;
    }
    &__subtitle {
      width: 78%;
      margin: 0 auto;
      font-size: 14px;
      font-weight: normal;
      color: #707070;
    }
    &__text {
      height: 45px;
      width: 100%;
      margin: 0;
      font-size: 17px;
      font-weight: bold;
      line-height: 45px;
      border-top: 1px solid rgb(201, 201, 201);
      box-sizing: border-box;
      &--top {
        @extend .dialog__area__text;
        margin-top: 41px;
        color: #ff9534;
      }
      &--bottom {
        @extend .dialog__area__text;
        color: #707070;
      }
    }
  }
}
</style>
