<template>
  <div class="box">
    <div :class="selected === 'dislike' ? 'box__area--active' : 'box__area'" @click="selected === 'dislike' ? selected = '' : selected = 'dislike'">
      <div :class="selected === 'dislike' ? 'box__area__icon--active' : 'box__area__icon'">
        <img class="box__area__icon__dislike" :src="selected !== 'dislike' ? require('@/assets/img/dislike-gray.svg') : require('@/assets/img/dislike-white.svg')">
      </div>
      <p class="box__area__text">Nội dung học ko đủ</p>
    </div>
    <div :class="selected === 'education' ? 'box__area--active' : 'box__area'" @click="selected === 'education' ? selected = '' : selected = 'education'">
      <div :class="selected === 'education' ? 'box__area__icon--active' : 'box__area__icon'">
        <img class="box__area__icon__education" :src="selected !== 'education' ? require('@/assets/img/education-gray.svg') : require('@/assets/img/education-white.svg')">
      </div>
      <p class="box__area__text">Đã tìm thấy tài liệu học khác tốt hơn</p>
    </div>
    <div :class="selected === 'battery' ? 'box__area--active' : 'box__area'" @click="selected === 'battery' ? selected = '' : selected = 'battery'">
      <div :class="selected === 'battery' ? 'box__area__icon--active' : 'box__area__icon'">
        <img class="box__area__icon__battery" :src="selected !== 'battery' ? require('@/assets/img/battery-gray.svg') : require('@/assets/img/battery-white.svg')">
      </div>
      <p class="box__area__text">Ứng dụng không thân thiện với người dùng</p>
    </div>
    <div :class="selected === 'dots' ? 'box__area--active' : 'box__area'" @click="selected === 'dots' ? selected = '' : selected = 'dots'">
      <div :class="selected === 'dots' ? 'box__area__icon--active' : 'box__area__icon'">
        <img class="box__area__icon__dots" :src="selected !== 'dots' ? require('@/assets/img/dots-gray.svg') : require('@/assets/img/dots-white.svg')">
      </div>
      <p class="box__area__text">Lý do khác</p>
    </div>
    <button :class="selected.length === 0 ? 'box__btn' : 'box__btn--active'" @click="$emit('set-dialog', { isOpen: true, reason: reason })"
    :disabled="selected.length === 0">Gởi phản hồi</button>
  </div>
</template>

<script>
export default {
  props: {
  },
  data () {
    return {
      // 選択された理由
      // dislike, education, battery, dots
      selected: ''
    }
  },
  mounted () {
  },
  computed: {
    /**
     * @return {String} 退会理由
     */
    reason () {
      return this.selected === 'dislike' ? 'The content is not enough for studying'
        : this.selected === 'education' ? 'Found other material that is better than us'
          : this.selected === 'battery' ? 'Application is not friendlly for users'
            : this.selected === 'dots' ? 'Other'
              : ''
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.box {
  width: 100%;
  font-size: 0;
  box-sizing: border-box;
  &__area {
    display: inline-block;
    vertical-align: top;
    height: 50vw;
    width: 50vw;
    padding: calc((50vw - 120px)/2) 20px;
    border-top: 1px solid #d2d2d2;
    border-left: 1px solid #d2d2d2;
    box-sizing: border-box;
    &--active {
      @extend .box__area;
      background-color: #feead8;
    }
    &:nth-child(even) {
      border-right: 1px solid #d2d2d2;
    }
    &:nth-child(3) {
      border-bottom: 1px solid #d2d2d2;
    }
    &:nth-child(4) {
      border-bottom: 1px solid #d2d2d2;
    }
    &__icon {
      height: 78px;
      width: 78px;
      margin: 0 auto;
      text-align: center;
      border-radius: 50%;
      background-color: #d2d2d2;
      &--active {
        @extend .box__area__icon;
        background-color: #ff9534;
      }
      &__dislike {
        height: 30%;
        margin-top: calc((78px - 30%) / 2);
      }
      &__education {
        height: 42%;
        margin-top: calc((78px - 42%) / 2);
      }
      &__battery {
        height: 23.5%;
        margin-top: calc((78px - 23.5%) / 2);
      }
      &__dots {
        height: 7.4%;
        margin-top: calc((78px - 7.4%) / 2);
      }
    }
    &__text {
      margin: 0;
      margin-top: 5px;
      font-size: 12px;
      color: #707070;
    }
  }
  &__btn {
    height: 44px;
    width: 231px;
    margin-top: 21px;
    font-size: 17px;
    color: white;
    border-radius: 40px;
    background-color: #a5a5a5;
    &--active {
      @extend .box__btn;
      background-color: #ff9534;
    }
  }
}
</style>
